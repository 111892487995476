import {
  GET_ADD_TAG_LISTINGS_REQUEST,
  GET_ADD_TAG_LISTINGS_FAIL,
  GET_ADD_TAG_LISTINGS_SUCCESS,
  ADD_TAG_REQUEST,
  ADD_TAG_FAIL,
  ADD_TAG_SUCCESS,
  GET_TAGS_REQUEST,
  GET_TAGS_FAIL,
  GET_TAGS_SUCCESS,
  EDIT_TAG_REQUEST,
  EDIT_TAG_FAIL,
  EDIT_TAG_SUCCESS,
  DELETE_TAG_REQUEST,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_FAIL,
  GET_ALL_TAGS_FOR_LISTING_REQUEST,
  GET_ALL_TAGS_FOR_LISTING_FAIL,
  GET_ALL_TAGS_FOR_LISTING_SUCCESS,
  SET_TAG_FILTERS
} from "./tagTypes";

const initialState = {
  loading: false,
  listings: [],
  tags: [],
  success: false,
  tagsForListing: [],
  filterTags: []
};

const tagReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADD_TAG_LISTINGS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        listing: []
      };
    case GET_TAGS_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        tags: []
      };
    case ADD_TAG_REQUEST:
    case EDIT_TAG_REQUEST:
    case DELETE_TAG_REQUEST:
    case GET_ALL_TAGS_FOR_LISTING_REQUEST:
      return {
        ...state,
        loading: true,
        success: false
      };
    case GET_ADD_TAG_LISTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        listings: action.payload
      };
    case ADD_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true
      };
    case EDIT_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        tags: state.tags.map((tag) => (tag._id.toString() === action.payload._id.toString() ? { ...tag, ...action.payload } : tag))
      };
    case DELETE_TAG_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        tags: state.tags.filter((tag) => tag._id !== action.payload)
      };
    case GET_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        tags: action.payload
      };
    case GET_ALL_TAGS_FOR_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        tagsForListing: action.payload
      };
    case SET_TAG_FILTERS: {
      return {
        ...state,
        filterTags: action.payload
      };
    }
    case GET_ADD_TAG_LISTINGS_FAIL:
    case ADD_TAG_FAIL:
    case GET_TAGS_FAIL:
    case EDIT_TAG_FAIL:
    case DELETE_TAG_FAIL:
    case GET_ALL_TAGS_FOR_LISTING_FAIL:
      return {
        ...state,
        loading: false,
        success: false
      };
    default:
      return state;
  }
};

export default tagReducer;
