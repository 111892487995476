export const GET_ADD_TAG_LISTINGS_REQUEST = "GET_ADD_TAG_LISTINGS_REQUEST";
export const GET_ADD_TAG_LISTINGS_FAIL = "GET_ADD_TAG_LISTINGS_FAIL";
export const GET_ADD_TAG_LISTINGS_SUCCESS = "GET_ADD_TAG_LISTINGS_SUCCESS";
export const ADD_TAG_REQUEST = "ADD_TAG_REQUEST";
export const ADD_TAG_SUCCESS = "ADD_TAG_SUCCESS";
export const ADD_TAG_FAIL = "ADD_TAG_FAIL";
export const EDIT_TAG_REQUEST = "EDIT_TAG_REQUEST";
export const EDIT_TAG_SUCCESS = "EDIT_TAG_SUCCESS";
export const EDIT_TAG_FAIL = "EDIT_TAG_FAIL";
export const GET_TAGS_REQUEST = "GET_TAGS_REQUEST";
export const GET_TAGS_FAIL = "GET_TAGS_FAIL";
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS";
export const DELETE_TAG_REQUEST = "DELETE_TAG_REQUEST";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_FAIL = "DELETE_TAG_FAIL";
export const GET_ALL_TAGS_FOR_LISTING_REQUEST = "GET_ALL_TAGS_FOR_LISTING_REQUEST";
export const GET_ALL_TAGS_FOR_LISTING_FAIL = "GET_ALL_TAGS_FOR_LISTING_FAIL";
export const GET_ALL_TAGS_FOR_LISTING_SUCCESS = "GET_ALL_TAGS_FOR_LISTING_SUCCESS";
export const SET_TAG_FILTERS = "SET_TAG_FILTERS";
