import { setUserDataInLocalStorage } from "_shared/functions";
import {
  SET_SIGNUP_PAYLOAD,
  SET_USER_DATA,
  SET_USER_ROLE,
  SET_ADDRESSES,
  CREAT_USER_ACCOUNT_FAILURE,
  CREAT_USER_ACCOUNT_REQUEST,
  CREAT_USER_ACCOUNT_SUCCESS,
  SET_STEPS,
  VERIFICATION_REQUEST,
  VERIFICATION_FAILURE,
  VERIFICATION_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  CLEAR_LOCALSTORAGE_SIGNUP_COMPLETE,
  RESEND_LINK,
  INVITE_LANDLORD_REQUEST,
  INVITE_LANDLORD_SUCCESS,
  INVITE_LANDLORD_FAILURE,
  LOGIN_REQUEST,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  VERIFY_FORGOT_TOKEN_REQUEST,
  VERIFY_FORGOT_TOKEN_SUCCESS,
  VERIFY_FORGOT_TOKEN_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  SET_LOADING,
  SET_PROFILE_IMAGE,
  SET_MY_DETAILS,
  GET_ACCOUNT_DETAILS_REQUEST,
  GET_ACCOUNT_DETAILS_SUCCESS,
  GET_ACCOUNT_DETAILS_FAILURE,
  GET_SUBSCRIPTION_DETAILS_REQUEST,
  GET_SUBSCRIPTION_DETAILS_SUCCESS,
  GET_SUBSCRIPTION_DETAILS_FAILURE,
  NEED_PASSWORD_CHANGE
} from "./userTypes";
import { removeItemsFromLocalStorage, setItemsToLocalStorage } from "utils";

const fetchPayload = () => {
  const payload = localStorage.getItem("signupPayload");
  if (payload) {
    return JSON.parse(payload);
  } else {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      contact: "",
      businessName: "",
      postCode: "",
      address: "",
      role: "",
      name: "",
      tenantEmail: "",
      landlordEmail: "",
      trade: "",
      isLoggedIn: false,
      isProfileComplete: false
    };
  }
};

const fetchAddress = () => {
  const payload = localStorage.getItem("address");
  if (payload) {
    return setAddressFormat(JSON.parse(payload));
  } else {
    return [];
  }
};

const setAddressFormat = (json) => {
  const data = [];
  if (json && json.thoroughfares && json.thoroughfares.length)
    for (const element of json.thoroughfares) {
      for (let j = 0; j < element.delivery_points.length; j++) {
        let subName = element.delivery_points[j].sub_building_name;
        let buildingName = element.delivery_points[j].building_name;
        let thoroughfare_name = element.thoroughfare_name;
        let builinNumber = element.delivery_points[j].building_number;
        let thoroughfare_descriptor = element.thoroughfare_descriptor;
        let dependent_locality = json.dependent_locality;
        let organisation_name = element.delivery_points[j].organisation_name;

        let character = "";
        character +=
          organisation_name !== ""
            ? organisation_name.split(" ").reduce((a, b, ind) => {
                if (ind === 0) {
                  a += b[0].toUpperCase() + b.slice(1).toLowerCase().trimStart() + " ";
                } else {
                  a += b[0].toUpperCase() + b.slice(1).toLowerCase() + " ";
                }
                return a;
              }, "")
            : "";
        character += subName !== "" ? subName[0].toUpperCase() + subName.slice(1).toLowerCase() + " " : "";
        character +=
          buildingName !== ""
            ? buildingName.split(" ").reduce((a, b, ind) => {
                if (ind === 0) {
                  a += b[0].toUpperCase() + b.slice(1).toLowerCase().trimStart() + " ";
                } else {
                  a += b[0].toUpperCase() + b.slice(1).toLowerCase() + " ";
                }
                return a;
              }, "")
            : "";
        character += builinNumber !== "" ? builinNumber + " " : "";
        character += thoroughfare_name !== "" ? thoroughfare_name[0].toUpperCase() + thoroughfare_name.slice(1).toLowerCase() + " " : "";
        character +=
          thoroughfare_descriptor !== "" ? thoroughfare_descriptor[0].toUpperCase() + thoroughfare_descriptor.slice(1).toLowerCase() + " " : "";

        character +=
          dependent_locality !== ""
            ? dependent_locality.includes(" ")
              ? dependent_locality.split(" ")[0][0].toUpperCase() + dependent_locality.split(" ")[0].slice(1).toLowerCase() + ", "
              : dependent_locality[0].toUpperCase() + dependent_locality.slice(1).toLowerCase() + ", "
            : "";
        character += json.town[0].toUpperCase() + json.town.slice(1).toLowerCase() + " " + json.postcode;
        data.push(character);
      }
    }
  return data;
};

const getFromLocalStorage = (step) => {
  const payload = localStorage.getItem(step);
  if (payload) {
    return JSON.parse(payload);
  } else return 0;
};

const initialsState = {
  userData: {
    contact: "",
    email: "",
    firstName: "",
    jwtToken: "",
    lastName: "",
    role: "",
    isLoggedIn: false,
    isProfileComplete: false,
    trade: "",
    businessName: "",
    profileImage: ""
  },
  liaisonFirstLogin: {
    firstLogin: false,
    userId: ""
  },
  signupPayload: fetchPayload(),
  addresses: fetchAddress(),
  loading: false,
  step: getFromLocalStorage("step"),
  subStep: getFromLocalStorage("subStep"),
  showStepper: getFromLocalStorage("step") >= 0 && getFromLocalStorage("subStep") !== 0,
  isVerified: JSON.parse(localStorage.getItem("isVerified")),
  isResendLinkSent: JSON.parse(localStorage.getItem("isResendLinkSent")) ? JSON.parse(localStorage.getItem("isResendLinkSent")) : false,
  role: localStorage.getItem("role"),
  isProfileComplete: JSON.parse(localStorage.getItem("isProfileComplete")),
  inviteSent: JSON.parse(localStorage.getItem("inviteSent")) ? JSON.parse(localStorage.getItem("inviteSent")) : false,
  forgotLinkSent: false,
  resetPassword: false,
  subscriptionDetails: {},
  accountDetails: {
    contactDetails: {
      contact: "",
      secondaryContact: "",
      emergencyContact: "",
      contactEmail: ""
    }
  }
};

let items = [];
const userReducer = (state = initialsState, action) => {
  switch (action.type) {
    case CREAT_USER_ACCOUNT_REQUEST:
    case UPDATE_USER_REQUEST:
    case INVITE_LANDLORD_REQUEST:
    case LOGIN_REQUEST:
    case FORGOT_PASSWORD_REQUEST:
    case VERIFY_FORGOT_TOKEN_REQUEST:
    case RESET_PASSWORD_REQUEST:
    case SET_LOADING:
    case GET_ACCOUNT_DETAILS_REQUEST:
    case GET_SUBSCRIPTION_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case VERIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        step: 1,
        subStep: 0
      };
    case VERIFY_FORGOT_TOKEN_SUCCESS:
      return {
        ...state,
        isVerified: true,
        loading: false,
        tokenVerified: true
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: true,
        loading: false
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotLinkSent: true,
        loading: false
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false
      };
    case VERIFY_FORGOT_TOKEN_FAILURE:
      return {
        ...state,
        tokenVerified: false,
        loading: false
      };
    case CREAT_USER_ACCOUNT_SUCCESS:
    case LOGIN_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        loading: false
      };
    case INVITE_LANDLORD_SUCCESS:
      localStorage.setItem("inviteSent", true);
      return {
        ...state,
        loading: false
      };
    case CREAT_USER_ACCOUNT_FAILURE:
    case UPDATE_USER_FAILURE:
    case INVITE_LANDLORD_FAILURE:
    case LOGIN_FAIL:
    case GET_ACCOUNT_DETAILS_FAILURE:
    case GET_SUBSCRIPTION_DETAILS_FAILURE:
      return {
        ...state,
        loading: false
      };
    case VERIFICATION_FAILURE:
      return {
        ...state,
        userData: { isProfileComplete: false },
        isVerified: false,
        step: 1,
        subStep: 0,
        loading: false
      };
    case VERIFICATION_SUCCESS:
      localStorage.setItem("isVerified", true);
      localStorage.setItem("role", action.payload.user.role);
      setUserDataInLocalStorage(action.payload.user);
      return {
        ...state,
        isVerified: true,
        userData: action.payload.user,
        step: 1,
        subStep: 0,
        loading: false
      };
    case UPDATE_USER_SUCCESS:
      localStorage.setItem("isProfileComplete", true);
      return {
        ...state,
        userData: { ...state.userData, ...action.payload },
        loading: false,
        step: 2,
        subStep: 0,
        isProfileComplete: true
      };
    case SET_USER_DATA:
      if (JSON.parse(localStorage.getItem("isProfileComplete")))
        action.payload.isProfileComplete = JSON.parse(localStorage.getItem("isProfileComplete"));
      return {
        ...state,
        userData: action.payload
      };
    case SET_STEPS:
      items = [
        { name: "step", value: action.payload.step },
        { name: "subStep", value: action.payload.subStep },
        { name: "showStepper", value: action.payload.step !== 0 }
      ];
      setItemsToLocalStorage(items);
      return {
        ...state,
        step: action.payload.step,
        subStep: action.payload.subStep,
        showStepper: action.payload.step !== 0
      };
    case SET_SIGNUP_PAYLOAD:
      items = [{ name: "signupPayload", value: { ...state.signupPayload, ...action.payload } }];
      setItemsToLocalStorage(items);
      return {
        ...state,
        signupPayload: { ...state.signupPayload, ...action.payload }
      };
    case CLEAR_LOCALSTORAGE_SIGNUP_COMPLETE:
      items = ["isVerified", "subStep", "step", "signupPayload", "address", "showStepper"];
      removeItemsFromLocalStorage(items);
      return {
        userData: state.userData
      };
    case SET_USER_ROLE:
      return {
        ...state,
        signupPayload: { ...state.signupPayload, role: action.payload }
      };
    case SET_ADDRESSES:
      items = [{ name: "address", value: action.payload }];
      setItemsToLocalStorage(items);
      return {
        ...state,
        addresses: setAddressFormat(action.payload),
        loading: false
      };
    case RESEND_LINK:
      items = [{ name: "isResendLinkSent", value: true }];
      setItemsToLocalStorage(items);
      return {
        ...state,
        loading: false,
        isResendLinkSent: true
      };
    case SET_PROFILE_IMAGE:
      items = [{ name: "profileImage", value: action.payload.profileImage }];
      setItemsToLocalStorage(items);
      return {
        ...state,
        userData: { ...state.userData, profileImage: action.payload.profileImage },
        loading: false
      };
    case SET_MY_DETAILS:
      return {
        ...state,
        userData: {
          ...state.userData,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          businessName: action.payload.businessName,
          contact: action.payload.contact,
          jwtToken: action.payload.jwtToken
        },
        loading: false
      };
    case GET_ACCOUNT_DETAILS_SUCCESS:
      return {
        ...state,
        accountDetails: {
          contactDetails: action.payload.details.contactDetails
        },
        loading: false
      };
    case GET_SUBSCRIPTION_DETAILS_SUCCESS:
      return {
        ...state,
        subscriptionDetails: action.payload,
        loading: false
      };
    case NEED_PASSWORD_CHANGE:
      return {
        ...state,
        liaisonFirstLogin: action.payload
      };

    default:
      return state;
  }
};

export default userReducer;
