export const Roles = {
  Landlord: "Landlord",
  Tenant: "Tenant",
  TradesPerson: "Tradesperson",
  Liaison: "Liaison"
};
export const commentRoles = {
  Landlord: "Landlord",
  Tenant: "Tenant",
  Tradesperson: "Tradesperson",
  Liaison: "Property Liaison"
};
export const TradespersonTypes = Object.freeze({
  Landlord: "Landlord",
  Liaison: "Liaison",
  Tradesperson: "Tradesperson"
});
export const FilterTicketStatus = {
  New: "New",
  In_Progress: "In Progress",
  Closed: "Closed"
};
export const SnackbarSeverity = {
  warning: "warning",
  info: "info",
  success: "success",
  dange: "danger"
};

export const Store = {
  isLoggedIn: "isLoggedIn",
  role: "role",
  userId: "userId",
  token: "token",
  isProfileComplete: "isProfileComplete",
  email: "email",
  isEmailVerified: "isEmailVerified",
  businessName: "businessName",
  createdAt: "createdAt",
  updatedAt: "updatedAt",
  contact: "contact",
  profileImage: "profileImage",
  liaisonFirstLogin: "liaisonFirstLogin",
  landlordBusinessName: "landlordBusinessName",
  expireSoon: "expireSoon"
};

export const IntialUserState = {
  userData: {
    contact: "",
    email: "",
    firstName: "",
    jwtToken: "",
    lastName: "",
    role: "",
    isLoggedIn: false
  },
  signupPayload: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    mobile: "",
    businessName: "",
    postCode: "",
    address: ""
  }
};

export const TicketStatus = Object.freeze({
  New: "New",
  Open: "Open",
  OpenAssigned: "OpenAssigned",
  Closed: "Closed",
  Reopen: "Reopen"
});

export const TicketSeverity = {
  Normal: "Normal",
  // Emergency: "Emergency",
  Urgent: "Urgent"
};

export const PropertyExtras = [
  {
    internet: "High Speed Internet"
  },
  {
    utility: "Separate Utility"
  },
  {
    parking: "Off Road Parking"
  },
  {
    oneMinWalk: "1 Minute Walk to Campus"
  },
  {
    twoMinWalk: "2 Minute Walk to Campus"
  },
  {
    fiveMinWalk: "5 Minute Walk to Campus"
  },
  {
    livingRoom: "Separate Living Room"
  },
  {
    garden: "Garden Maintenance"
  },
  {
    bicycle: "Bicycle Shed"
  },
  {
    livingArea: "Open-plan Living Area"
  },
  {
    refurbished: "Newly Refurbished"
  }
];

export const ActivityTimeLine = Object.freeze({
  Today: "Today",
  Yesterday: "Yesterday",
  This_Week: "This Week",
  Last_Week: "Last Week"
});

export const ActivityTimeLineSortOrder = Object.freeze({
  Today: 1,
  Yesterday: 2,
  "This Week": 3,
  "Last Week": 4
});

export const ageProfileChartXLabels = Object.freeze({
  "< 3 days": "< 3 days",
  "4-7 days": "4-7 days",
  "8-21 days": "8-21 days",
  "> 21 days": "> 21 days"
});
export const ageProfileStackLabels = Object.freeze({
  New: "New",
  InProgress: "InProgress"
});

export const TicketAgeFilter = Object.freeze({
  less_3: "< 3 days",
  between_4_7: "4-7 days",
  between_8_21: "8-21 days",
  more_21: "> 21 days"
});

export const ageProfileStackLabelsColors = Object.freeze({
  New: "#2876A7",
  InProgress: "#83C6EC"
});

const inProgressLabel = Object.freeze({
  Check_Completion: "Check Completion",
  Tradeperson_Assigned: "Tradesperson Assigned",
  Job_Booked: "Job Booked",
  Validate_job: "Validate Job",
  Ready_to_Close: "Ready to Close"
});
export { inProgressLabel };

const inProgressLabelTradesperson = Object.freeze({
  Job_Booked: "Job Booked",
  Confirm_Completion: "Confirm Completion"
});
export { inProgressLabelTradesperson };

export const inProgressLabelSortOrder = Object.freeze({
  [inProgressLabel.Ready_to_Close]: 5,
  [inProgressLabel.Check_Completion]: 4,
  [inProgressLabel.Job_Booked]: 3,
  [inProgressLabel.Tradeperson_Assigned]: 2,
  [inProgressLabel.Validate_job]: 1
});
export const inProgressLabelSortOrderTradesperson = Object.freeze({
  [inProgressLabelTradesperson.Job_Booked]: 1,
  [inProgressLabelTradesperson.Confirm_Completion]: 2
});

export const inProgressLabelColors = Object.freeze({
  [inProgressLabel.Validate_job]: "#034671",
  [inProgressLabel.Tradeperson_Assigned]: "#2876A7",
  [inProgressLabel.Job_Booked]: "#479ACE",
  [inProgressLabel.Check_Completion]: "#9CD0EE",
  [inProgressLabel.Ready_to_Close]: "#D2EFFF"
});
export const inProgressLabelTextColorColors = Object.freeze({
  [inProgressLabel.Validate_job]: "white",
  [inProgressLabel.Tradeperson_Assigned]: "white",
  [inProgressLabel.Job_Booked]: "white",
  [inProgressLabel.Check_Completion]: "black",
  [inProgressLabel.Ready_to_Close]: "black"
});

export const inProgressLabelColorsTradesperson = Object.freeze({
  [inProgressLabelTradesperson.Job_Booked]: "#034671",
  [inProgressLabelTradesperson.Confirm_Completion]: "#479ACE"
});

export const AllTicketFilters = Object.freeze({
  status: "status",
  property: "property",
  tradesPerson: "tradesPerson",
  landlord: "landlord"
});
