import { api } from "_endpoints/api";
import http from "../../axios";
import {
  DASHBOARD_ANALYTICS_REQUEST,
  DASHBOARD_ANALYTICS_FAILURE,
  DASHBOARD_ANALYTICS_SUCCESS,
  FETCH_MY_JOBS_REQUEST,
  FETCH_MY_JOBS_SUCCESS,
  FETCH_MY_JOBS_FAIL
} from "./types";

export const getAnalytics = () => {
  return async (dispatch, getState) => {
    try {
      const { filterTags } = getState().tag;
      dispatch({ type: DASHBOARD_ANALYTICS_REQUEST });
      const response = await http.post(api.dashboard.analytics, { filterTags: filterTags });
      dispatch({ type: DASHBOARD_ANALYTICS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: DASHBOARD_ANALYTICS_FAILURE, payload: error.response.data.message });
    }
  };
};

export const getJobs = () => {
  return async (dispatch, getState) => {
    try {
      const { filterTags } = getState().tag;
      dispatch({ type: FETCH_MY_JOBS_REQUEST });
      const response = await http.post(api.tickets.getAllTickets, { filterTags: filterTags });
      dispatch({ type: FETCH_MY_JOBS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: FETCH_MY_JOBS_FAIL, payload: error.response?.data?.message });
    }
  };
};
