import axios from "axios";
import { BASE_URL } from "environment";
import { Store } from "_enums/enums";
import { SetLoadingStatus } from "./_shared/functions";
import store from "./config/configureStore"; // Import the store instance
import { userLogout } from "redux/user/userActions";
// import { setExpire } from "redux/errors/errorActions";

const instance = axios.create({
  baseURL: BASE_URL
});

instance.interceptors.request.use(
  (req) => {
    SetLoadingStatus(true);
    const token = localStorage.getItem(Store.token);
    if (token) {
      req.headers["Authorization"] = `Bearer ${token}`;
    }
    return req;
  },
  (error) => {
    SetLoadingStatus(false);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    SetLoadingStatus(false);
    return res;
  },
  async (error) => {
    SetLoadingStatus(false);
    if (error.response && error.response.status === 403) {
      store.dispatch(userLogout());
    }

    return Promise.reject(error);
  }
);

export default instance;
