import {
  FETCH_ADDRESSESS_REQUEST,
  FETCH_ADDRESSESS_SUCCESS,
  FETCH_ADDRESSESS_FAIL,
  CREATE_PROPERTY_REQUEST,
  CREATE_PROPERTY_SUCCESS,
  CREATE_PROPERTY_FAIL,
  GET_ALL_LISTING_REQUEST,
  GET_ALL_LISTING_SUCCESS,
  GET_ALL_LISTING_FAIL,
  GET_SINGLE_PROPERTY_REQUEST,
  GET_SINGLE_PROPERTY_FAIL,
  GET_SINGLE_PROPERTY_SUCCESS,
  INVITE_TENANTS_REQUEST,
  INVITE_TENANTS_FAIL,
  INVITE_TENANTS_SUCCESS,
  SEND_CREATE_ACCOUNT_REMINDER_REQUEST,
  SEND_CREATE_ACCOUNT_REMINDER_FAIL,
  SEND_CREATE_ACCOUNT_REMINDER_SUCCESS,
  DELETE_TENANTS_FROM_PROPERTY_SUCCESS,
  DELETE_TENANTS_FROM_PROPERTY_REQUEST,
  DELETE_TENANTS_FROM_PROPERTY_FAIL,
  UPDATE_TENANCY_PERIOD_REQUEST,
  UPDATE_TENANCY_PERIOD_SUCCESS,
  UPDATE_TENANCY_PERIOD_FAIL,
  UPDATE_PROPERTY_REQUEST,
  UPDATE_PROPERTY_SUCCESS,
  UPDATE_PROPERTY_FAIL,
  DELETE_BEDROOM_REQUEST,
  DELETE_BEDROOM_SUCCESS,
  DELETE_BEDROOM_FAIL,
  ADD_BEDROOMS_REQUEST,
  ADD_BEDROOMS_SUCCESS,
  ADD_BEDROOMS_FAIL,
  UPDATE_BEDROOM_IDENTIFIER_REQUEST,
  UPDATE_BEDROOM_IDENTIFIER_SUCCESS,
  UPDATE_BEDROOM_IDENTIFIER_FAIL,
  UPDATE_PROPERTY_TAGS_REQUEST,
  UPDATE_PROPERTY_TAGS_SUCCESS,
  UPDATE_PROPERTY_TAGS_FAIL
} from "./propertyTypes";

const initialState = {
  loading: false,
  addressess: [],
  listings: [],
  success: false,
  singleProperty: null,
  overallActiveTenants: 0,
  overallActiveAssignedTeants: 0
};

const propertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ADDRESSESS_REQUEST:
    case CREATE_PROPERTY_REQUEST:
    case GET_ALL_LISTING_REQUEST:
    case GET_SINGLE_PROPERTY_REQUEST:
    case INVITE_TENANTS_REQUEST:
    case SEND_CREATE_ACCOUNT_REMINDER_REQUEST:
    case DELETE_TENANTS_FROM_PROPERTY_REQUEST:
    case UPDATE_TENANCY_PERIOD_REQUEST:
      return {
        ...state,
        loading: true,
        success: false,
        addressess: []
      };
    case DELETE_BEDROOM_REQUEST:
    case UPDATE_PROPERTY_REQUEST:
    case UPDATE_PROPERTY_TAGS_REQUEST:
    case ADD_BEDROOMS_REQUEST:
    case UPDATE_BEDROOM_IDENTIFIER_REQUEST:
      return {
        ...state,
        loading: true,
        success: false
      };
    case FETCH_ADDRESSESS_SUCCESS:
      return {
        ...state,
        addressess: action.payload,
        loading: false
      };
    case CREATE_PROPERTY_SUCCESS:
      return {
        ...state,
        listings: [...state.listings, action.payload],
        loading: false,
        success: true
      };
    case CREATE_PROPERTY_FAIL:
      return {
        ...state,
        listings: state.listings,
        loading: false,
        success: false
      };
    case GET_ALL_LISTING_SUCCESS:
      return {
        ...state,
        listings: action.payload.listings,
        overallActiveTenants: action.payload.overallActiveTenants ?? 0,
        overallActiveAssignedTeants: action.payload.overallActiveAssignedTeants ?? 0,
        loading: false
      };
    case GET_SINGLE_PROPERTY_SUCCESS:
    case SEND_CREATE_ACCOUNT_REMINDER_SUCCESS:
    case DELETE_TENANTS_FROM_PROPERTY_SUCCESS:
    case INVITE_TENANTS_SUCCESS:
    case UPDATE_TENANCY_PERIOD_SUCCESS:
    case UPDATE_PROPERTY_SUCCESS:
    case UPDATE_PROPERTY_TAGS_SUCCESS:
    case DELETE_BEDROOM_SUCCESS:
    case ADD_BEDROOMS_SUCCESS:
    case UPDATE_BEDROOM_IDENTIFIER_SUCCESS:
      return {
        ...state,
        singleProperty: action.payload,
        loading: false
      };
    case INVITE_TENANTS_FAIL:
      return {
        ...state,
        loading: false
      };
    case FETCH_ADDRESSESS_FAIL:
      return {
        ...state,
        loading: false
      };
    case GET_ALL_LISTING_FAIL:
    case GET_SINGLE_PROPERTY_FAIL:
    case SEND_CREATE_ACCOUNT_REMINDER_FAIL:
    case DELETE_TENANTS_FROM_PROPERTY_FAIL:
    case UPDATE_TENANCY_PERIOD_FAIL:
    case UPDATE_PROPERTY_FAIL:
    case DELETE_BEDROOM_FAIL:
    case ADD_BEDROOMS_FAIL:
    case UPDATE_BEDROOM_IDENTIFIER_FAIL:
    case UPDATE_PROPERTY_TAGS_FAIL:
      return {
        loading: false,
        success: false
      };

    default:
      return state;
  }
};

export default propertyReducer;
